<template lang="html">

    <section class="site-map">
        <bannerHead dataTitle="Política de Privacidad"></bannerHead>
        <breacrumbs dataTitle="Política de Privacidad"></breacrumbs>
        <div class="container list-map privacy">
            <h1>¿Quién es el responsable del tratamiento de sus datos?</h1>
            <p>El responsable del tratamiento de datos es la Universidad Francisco de Vitoria (UFV)</p>
            <p>Datos de contacto: Universidad Francisco de Vitoria. Secretaría General. Ctra. M-515 Pozuelo-Majadahonda
                Km. 1,800; 28223, Pozuelo de Alarcón (Madrid).</p>
            <p>Datos de contacto del Delegado de protección de datos: <a href="mailto:dpd@ufv.es">dpd@ufv.es</a></p>
            <h1>¿Con qué finalidad tratamos sus datos personales?</h1>
            <p>En la UNIVERSIDAD FRANCISCO DE VITORIA, vamos a tratar sus datos personales con la finalidad de gestionar
                y mantener la relación existente entre la empresa y sus empleados, y podríamos tratarlos, en su caso,
                para las siguientes finalidades:</p>
            <ul>
                <li class="dot">RECURSOS HUMANOS Y GESTIÓN DE NÓMINAS.- Selección, Contratación, Gestión, Promoción y/o
                    Formación; Gestión de nóminas y otros tipos de retribuciones. Gestión de Personal [Altas, Bajas,
                    Viajes y Pernoctas, Nóminas, Anticipos, Permisos y licencias, Vacaciones, Control de horario, en su
                    caso, descuento de la cuota obrera, así como otros aspectos del ámbito laboral]. Realizar todos
                    aquellos trámites administrativos, fiscales y contables necesarios para cumplir con nuestros
                    compromisos contractuales, obligaciones en materia de normativa laboral, Seguridad Social,
                    prevención de riesgos laborales, fiscal y contable.</li>
                <li class="dot">PREVENCIÓN DE RIESGOS LABORALES.- Gestión de la Prevención de Riesgos Laborales, con
                    tratamiento de información relativa al puesto de trabajo y situaciones de riesgo, así como formación
                    en la materia.</li>
                <li class="dot">FORMACIÓN.- Gestión de las inscripciones en los cursos de formación obligatoria. Control
                    de asistencia. Expedición de títulos. </li>
                <li class="dot">VIDEOVIGILANCIA.- La entidad está dotada de un sistema de videovigilancia a los efectos
                    de preservar la seguridad en las instalaciones o dependencias. En algún momento, podría ser
                    utilizado para el control laboral. En todo caso, ante la comisión de actos ilícitos o no éticos, las
                    imágenes serán conservadas para la puesta a disposición de las autoridades competentes.</li>
                <li class="dot">Control horario a través del sistema de control de acceso mediante huella en el caso de
                    contar con su consentimiento libre y voluntario. En caso contrario, le ofrecemos una alternativa
                    consistente en la introducción de sus fichajes mediante la aplicación Kelux o aquella otra que la
                    universidad ponga a su disposición.</li>
                <li class="dot">En algún momento, podría llevarse a cabo un control laboral a través de sistemas de
                    videovigilancia.</li>
            </ul>
            <p>Igualmente, la universidad podría en algún momento realizar un control laboral monitorizando la actividad
                del usuario en el correo electrónico profesional, pudiendo inclusive acceder al contenido de los
                correos; control de la actividad llevada a cabo en los equipos y dispositivos tanto físicos como
                móviles, accediendo a archivos, mensajería instantánea y navegación en Internet; instalación de
                programas de monitorización de la actividad del usuario. </p>
            <ul>
                <li class="dot">Gestión de los seguros colectivos / plan de pensiones de la empresa, seguros de vida,
                    seguros médicos, cheques comedor, cheques guardería y beneficios sociales.</li>
            </ul>
            <p>Adicionalmente, la información podrá ser tratada con las siguientes finalidades:</p>
            <ul>
                <li class="dot">Informarle de las actividades educativas, docentes, deportivas, culturales,
                    extracurriculares, becas y de voluntariado que pueda organizar o en las que pueda colaborar la
                    Universidad Francisco de Vitoria, así como invitarle a participar en encuestas de calidad. Las
                    convocatorias podrán ser remitidas incluso por medios electrónicos (a modo enunciativo, pero no
                    limitativo, SMS, correo electrónico, portal del empleado, aula virtual). Para ello, se le dará de
                    alta en los diferentes boletines electrónicos que posee la Universidad Francisco de Vitoria.
                </li>
                <li class="dot">
                    Publicar su nombre, su imagen (la foto corporativa o la imagen que usted haya subido a nuestros
                    sistemas) y otras informaciones relacionadas con la actividad de la universidad y el trabajo que
                    usted desarrolla en ella para que sus compañeros o alumnos puedan identificarle, en:
                    <ul>
                        <li class="dot">
                            Plataformas de acceso exclusivo para el personal o sus alumnos, como QUIÉN ES QUIÉN, el
                            listín de emails de los empleados de la UFV de MICROSOFT, Aula Virtual, etc.
                        </li>
                        <li class="dot">
                            Encuestas de opinión relacionadas con la actividad laboral diaria.
                        </li>
                        <li class="dot">
                            Orlas, anuarios, memorias y publicaciones de ámbito educativo o investigador. Cuando la
                            publicación se haga en internet en abierto se le pedirá su consentimiento libre y
                            voluntario, salvo que dicha publicación venga impuesta por el cumplimiento de una obligación
                            legal.
                        </li>
                        <li class="dot">
                            Correos electrónicos remitidos a la comunidad UFV.
                        </li>
                    </ul>
                    En el caso de que se le invitara a participar en actuaciones específicas de promoción de la
                    Universidad Francisco de Vitoria, se le pediría su consentimiento libre y voluntario documentalmente
                    si su imagen tuviera un carácter relevante.
                </li>
                <li class="dot">
                    Grabar y/o publicar en las plataformas contratadas por la universidad (CANVAS, BLACKBOARD, TEAMS,
                    ZOOM, etc.) videos e imágenes en las que pueda aparecer a raíz de su participación en las diferentes
                    actividades de la universidad, para lo que cede a la UFV, en exclusiva y, en el ámbito de su
                    relación laboral con la universidad, cuantos derechos le pudieran corresponder en virtud de lo
                    dispuesto en el Real Decreto Legislativo 1/1996, de 12 de abril, por el que se aprueba el texto
                    refundido de la Ley de Propiedad Intelectual, regularizando, aclarando y armonizando las
                    disposiciones legales vigentes sobre la materia y en la Ley Orgánica 1/1982, de 5 de mayo, de
                    protección civil del derecho al honor, a la intimidad personal y familiar y a la propia imagen, por
                    la cesión de su imagen y derechos de autor a la UFV, y otorga su consentimiento expreso para la
                    cesión de dichas imágenes y para su tratamiento conforme a la normativa vigente en España. La
                    grabación o publicación de imágenes en las que pueda aparecer por su participación en reuniones de
                    equipos de trabajo u otras actividades de la universidad como profesor, ponente o personal
                    organizador se basa en la ejecución de un contrato (como en clases online, exámenes o pruebas de
                    evaluación, congresos o seminarios, comisiones, etc.). Si la actividad fuera voluntaria y usted
                    asistiera como público (como una conferencia, seminario, jornada o congreso) y no se requiriera la
                    grabación de su imagen como acreditación de su asistencia, entonces usted prestará su consentimiento
                    a dicha grabación o publicación de la imagen si realiza una clara acción afirmativa, como conectar
                    su webcam y/o micrófono en el caso de actividades online, o si acude a una actividad presencial de
                    la universidad donde se advierta que se está grabando y se sitúa delante de las cámaras o interviene
                    realizando alguna pregunta o comentario a los ponentes.
                </li>
            </ul>
            <h1>¿Durante cuánto tiempo vamos a conservar sus datos personales?</h1>
            <ul>
                <li class="dot">
                    Sus datos personales serán conservados mientras dure la relación laboral con la UNIVERSIDAD
                    FRANCISCO DE VITORIA y, con posterioridad, se mantendrán de forma indefinida los datos mínimos
                    imprescindibles para poder emitirle certificados de su experiencia laboral, salvo que usted nos
                    solicite la supresión definitiva de los mismos.
                </li>
                <li class="dot">
                    Al finalizar la relación laboral, los datos personales tratados para cada una de las finalidades
                    indicadas se mantendrán durante los plazos legalmente previstos o los necesarios para cumplir con
                    las obligaciones legales de la universidad (por ejemplo, los datos de los currículums que el
                    profesorado elabore con las plataformas o sistemas de la universidad, se conservarán hasta que
                    transcurra un año desde que finalice su relación con la UFV, con el objeto de poder cumplir con las
                    obligaciones legales de la universidad, tales como la publicación de las memorias oficiales; y los
                    datos del profesorado que sea necesario conservar a efectos de la renovación de la acreditación de
                    los títulos se mantendrán mientras subsista dicha necesidad) o durante el plazo que un juez o
                    tribunal los pueda requerir atendiendo al plazo de prescripción de acciones judiciales. Los datos
                    tratados en base al consentimiento del interesado se mantendrán mientras sea necesario para la
                    finalidad del tratamiento y, con posterioridad, en tanto no expiren los plazos legales aludidos
                    anteriormente, si hubiera obligación legal de mantenimiento; o de no existir ese plazo legal, hasta
                    que el interesado solicite su supresión o revoque el consentimiento otorgado.
                </li>
                <li class="dot">
                    Las imágenes/sonidos captados por los sistemas de videovigilancia se conservarán durante el plazo
                    máximo de un mes desde su captación, de acuerdo con el artículo 22 de la Ley Orgánica 3/2018, de 5
                    de enero, de Protección de Datos Personales y garantía de los derechos digitales.
                </li>
            </ul>
            <h1>¿Cuál es la legitimación para el tratamiento de sus datos?</h1>
            <ul>
                <li class="dot"><strong>Ejecución de contrato o la aplicación de medidas precontractuales a petición del
                        interesado:</strong>La base legal para el tratamiento de sus datos es la ejecución del contrato
                    de trabajo o la adopción de medidas precontractuales.</li>
                <li class="dot"><strong>Cumplimiento de una obligación legal:</strong>
                    <ul>
                        <li class="circle">
                            Los tratamientos de datos derivados de las relaciones laborales están legitimados por el
                            Real Decreto Legislativo 2/2015, de 23 de octubre, por el que se aprueba el texto refundido
                            de la Ley del Estatuto de los Trabajadores, y demás normativa aplicable.
                        </li>
                        <li class="circle">
                            Obligación de protección en materia de Prevención de Riesgos Laborales, en virtud de la Ley
                            31/1995, de 8 de noviembre, de Prevención de Riesgos Laborales.
                        </li>
                        <li class="circle">
                            Suministro de información a las entidades gestoras de las prestaciones económicas de la
                            Seguridad Social de conformidad con el Real Decreto Legislativo 8/2015, de 30 de octubre,
                            por el que se aprueba el texto refundido de la Ley General de la Seguridad Social.
                        </li>
                        <li class="circle">
                            Comunicación de sus datos cuando así lo solicite la Inspección de Trabajo, conforme a lo
                            establecido en la Ley 23/2015, de 21 de julio, Ordenadora del Sistema de Inspección de
                            Trabajo y Seguridad Social.
                        </li>
                        <li class="circle">
                            Obligaciones tributarias de la entidad, conforme a la Ley 58/2003, de 17 de diciembre,
                            General Tributaria.
                        </li>
                    </ul>


                </li>
                <li class="dot"><strong>Consentimiento del interesado: </strong>firmando o marcando las casillas
                    correspondientes incluidas en la información básica de protección de datos de los empleados, o en la
                    información básica de aquellos formularios o impresos donde los empleados puedan solicitar
                    determinados servicios, como puede ser la suscripción a newsletters o el acceso a determinados
                    beneficios sociales o la inscripción voluntaria en determinadas actividades de la universidad; o
                    autorizando determinados usos de sus datos personales, como puede ser mediante la suscripción
                    voluntaria con la universidad de un contrato de cesión de derechos de imagen. </li>
            </ul>
            <h1>¿A qué destinatarios se comunicarán sus datos?</h1>
            <p>Sus datos serán comunicados a las entidades y organismos que se detallan a continuación:</p>
            <ul>
                <li class="dot">
                    A las entidades bancarias que corresponda, para el pago de las nóminas o el cobro de las actividades
                    en las que se inscriba, en su caso.
                </li>
                <li class="dot">
                    A la Administración tributaria.
                </li>
                <li class="dot">Organismos de la Seguridad Social, Mutua de Accidentes de Trabajo y Enfermedades
                    Profesionales de la Seguridad Social, Servicio Público de Empleo, Instituto Nacional de Estadística.
                </li>
                <li class="dot">
                    En el supuesto de que nos sea solicitado, sus datos serán cedidos a la Inspección de Trabajo.
                </li>
                <li class="dot">
                    A las entidades que participen en la gestión de cursos de formación a los que desee asistir el
                    trabajador con la finalidad de participar en los cursos que se organicen. En el caso de recibir
                    formación subvencionada, según establece la Ley Orgánica 5/2002 de 19 de junio, de las
                    Cualificaciones y de la Formación Profesional, así como en caso de contratos para la formación y el
                    aprendizaje previstos por el R.D. 1529/2012, de 8 de noviembre, deberá facilitarse a las
                    Administraciones públicas competentes toda la información requerida para el seguimiento y control de
                    las acciones formativas realizadas.
                </li>
                <li class="dot">
                    Ministerios o Consejerías con competencias en materia de educación y universidades.
                </li>
                <li class="dot">
                    Otras Administraciones Públicas competentes.
                </li>
                <li class="dot">
                    Agencias de Evaluación y Acreditación.
                </li>
                <li class="dot">
                    Entidades públicas o privadas a las que los alumnos acudan a realizar prácticas, voluntariado o
                    parte de su formación, o con las que la universidad gestione la formación conjuntamente.
                </li>
                <li class="dot">
                    En el caso de que usted desee realizar el voluntariado corporativo, a la entidad en la que vaya a
                    desarrollar dicha labor social.
                </li>
            </ul>
            <p>
                La Universidad Francisco de Vitoria cuenta con diversos proveedores de aplicaciones y soporte
                informático que pueden acceder a datos personales al ejecutar dicho contrato. La Universidad Francisco
                de Vitoria tendrá siempre suscrito con ellos un contrato de acceso a datos conforme a los requerimientos
                del artículo 28 del Reglamento General de Protección de Datos, que aportará garantías suficientes en los
                tratamientos realizados por estos prestadores. El tratamiento de datos efectuado por nuestros
                proveedores puede conllevar la realización de transferencias internacionales de datos, ya que algunos de
                los prestadores se encuentran fuera del territorio de la Unión Europea, por lo que la Universidad
                Francisco de Vitoria solicita garantías adicionales, según el régimen previsto para las transferencias
                internacionales en el Reglamento General de Protección de Datos.
            </p>
            <h1>
                ¿Cuáles son sus derechos en relación con el tratamiento de datos?
            </h1>
            <p>
                Cualquier persona tiene derecho a obtener información sobre la existencia de un tratamiento de sus
                datos, a acceder a sus datos personales, solicitar la rectificación de los datos que sean inexactos o,
                en su caso, solicitar la supresión, cuando entre otros motivos, los datos ya no sean necesarios para los
                fines para los que fueron recogidos o el interesado retire el consentimiento otorgado.
            </p>
            <p>
                En determinados supuestos, el interesado podrá solicitar la limitación del tratamiento de sus datos, en
                cuyo caso sólo los conservaremos de acuerdo con la normativa vigente.
            </p>
            <p>
                En determinados supuestos, puede ejercitar su derecho a la portabilidad de los datos, que serán
                entregados en un formato estructurado, de uso común o lectura mecánica a usted o el nuevo responsable de
                tratamiento que designe.
            </p>
            <p>
                Tiene derecho a revocar en cualquier momento el consentimiento para cualquiera de los tratamientos para
                los que lo ha otorgado.
            </p>
            <p>
                Para ejercer sus derechos, deberá hacerlo mediante el correspondiente email a <a
                    href="mailto:dpd@ufv.es">dpd@ufv.es</a> o mediante
                escrito firmado manual o electrónicamente. Si la universidad tuviera dudas sobre su identidad, le podrá
                requerir para que aporte copia de su DNI. Si se actúa por medio de representante, además de presentar un
                escrito firmado manual o electrónicamente, deberá acreditarse dicha representación.
            </p>
            <p>
                Los escritos deberán ser presentados presencialmente en el Registro de la Universidad o remitidos por
                correo postal en la dirección que aparece en el apartado “Responsable”.
            </p>
            <p>
                Tiene derecho a ponerse en contacto con nuestro delegado de protección de datos por lo que respecta a
                todas las cuestiones relativas al tratamiento de sus datos personales y al ejercicio de sus derechos al
                amparo del presente Reglamento y a presentar una reclamación ante la autoridad de control competente en
                el supuesto que considere que no se ha atendido convenientemente el ejercicio de sus derechos.
            </p>
            <p>
                El plazo máximo para resolver es el de un mes a contar desde la recepción de su solicitud, pudiendo ser
                prorrogado dos meses más atendiendo a la complejidad de la misma o al número de solicitudes recibidas.
            </p>
            <p>En el caso de producirse alguna modificación de sus datos, le agradecemos nos lo comunique debidamente
                por escrito con la finalidad de mantener sus datos actualizados.</p>
        </div>
    </section>

</template>
  
<script lang="js">
import { contentStore } from '@/stores/contents'
import bannerHead from '@/components/banner-head.vue'
import breacrumbs from '@/components/breacrumbs.vue'
export default {
    name: 'privacy-policy',
    props: [],
    components: {
        bannerHead,
        breacrumbs
    },
    setup() {
        const store = contentStore();

        return {
            store
        }
    },

}


</script>
  
<style scoped lang="scss">
.site-map {}
</style>